<template>
    <vs-popup :title="$t('billing.subscribe')" :active.sync="visible" id="popup-subscribe" @close="closePopup" ref="vspopup-subscribe">
      <div class="gap-4">
        <div class="w-full">
          <span class="flex items-center mb-1">
              <span class="text-lg">{{ $t('hello') }}, <b>{{ userName }}</b></span>
              <span class="text-sm	"> &nbsp; {{ userEmail }}</span>
          </span>
          <span class="flex items-center mb-1 mt-2" data="TEMPORÁRIO">
            Entre em contato com a nossa área comercial: <br>
            (61)99250-6834, ou <br>
            comercial@educatena.com.br <br>
          </span>
        </div>
        <div class="w-full" v-if="false">
          <label class="col-span-3 p- font-medium text-grey"> {{ $t('Economize 10% ao pagar antecipadamente') }} </label>
          <div class="gap-2 flex mt-2">
            <div @click="billing_cycle='MO'" :class="['ml-2 cursor-pointer', billing_cycle === 'MO' ? 'edu-switch-selected' : 'edu-switch']">
              <font-awesome-icon icon="dollar-sign" class="h-6 w-6 text-success" size="2xl"/>
              <span class="text-success ml-1">{{ $t('billing.pay_monthly') }}</span>
            </div>
            <div @click="billing_cycle='AN'" :class="['cursor-pointer', billing_cycle === 'AN' ? 'edu-switch-selected' : 'edu-switch']">
              <font-awesome-icon icon="dollar-sign" class="h-4 w-4 text-success" size="sm" beat-fade/>
              <span class="text-success ml-1">{{ $t('billing.pay_yearly') }}</span>
            </div>
          </div>
        </div>
        <div class="flex items-end justify-end mb-3" v-if="false">
          <edu-binary-switch
            flex-justify="end"
            class="text-right items-end"
            v-model="viewMode"
            :left-text="$t('mode_cards')"
            :right-text="$t('mode_table')"/>
        </div>
      </div>
      <transition :duration="500" mode="out-in" tag="div" name="slide-fade" v-if="false">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4" v-if="layout==='cards'">
          <vx-card :key="plan" v-for="(plan) in planList"
            class="w-auto shadow-drop border-solid border-grey border playful"
            headerStyle="w-full text-left font-bold text-white pb-4">
            <template v-slot:header>
              <div class="pt-2 flex items-center justify-between">
                <label class="_class-card-title">{{ _.get(plan, 'name')}}</label>
                <feather-icon
                  v-if="false"
                  svgClasses="w-5 h-5 2xl:w-4 2xl:h-4"
                  class="cursor-pointer"
                  icon="MoreVerticalIcon"/>
              </div>
            </template>
            <div class="flex flex-col w-full gap-3 justify-center">
              <p class="_class-card-description editor-content ck-content" v-html-safe="plan.description">
              </p>
              <div class="flex justify-center">
                <vs-button @click="subscribe(plan)" class="hover:animate-bounce transform hover:scale-110">
                    {{ $t('action.subscribe') }}
                </vs-button>
              </div>
            </div>
            <template v-slot:footer>
              <div class="w-full flex items-center justify-center">
                <span class="text-xl">{{formatValue(_.get(plan, 'value')) }}</span>
              </div>
            </template>
          </vx-card>
        </div>
        <vs-card v-if="layout==='table'">
          <div>
            <vs-table :data="planList"
                  :no-data-text="$t('nenhum-registro-encontrado')">
              <template slot="header">
                <h4>Plano(s)</h4>
              </template>
              <template slot="thead">
                <vs-th width="30%">{{ $t('plan.title') }}</vs-th>
                <vs-th width="20%">{{ $t('billing.value') }}</vs-th>
                <vs-th width="10%"></vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="data[i]" v-for="(tr, i) in data">
                  <vs-td>
                    {{data[i].name}}
                  </vs-td>
                  <vs-td>
                    <span>{{formatValue(_.get(data[i], 'value')) }}</span>
                  </vs-td>
                  <vs-td class="flex justify-center">
                    <vs-button @click="subscribe(data[i])" class="hover:animate-bounce transform hover:scale-125">
                        {{ $t('action.subscribe') }}
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-card>
      </transition>
      <div class="flex pt-2 items-center text-sm float-right flex-end gap-1">
        <font-awesome-icon icon="lock" class="h-3 w-3"/>
        <span>{{$t('secure_connection')}}</span>
      </div>
    </vs-popup>
</template>

<script>

//Components
import EduBinarySwitch from '@/components/EduBinarySwitch.vue'
import { currencyFormatter } from '@/util/Util'

//Services
import PlansService from '@/services/api/PlansService'

export default {
  components: {
    EduBinarySwitch
  },
  props: {
    visible :{
      type: Boolean,
      default: false
    },
    usageData:{
      tupe: Object,
      default: null
    }
  },
  data: () => ({
    service: null,
    selectedPlan: null,
    viewMode: false, // true = 'table', false = 'cards'
    billing_cycle: 'MO',
    planList: [],
    planListAll: []
  }),
  computed: {
    isInstitution(){
      return _.get(this.usageData, 'is_institution')
    },
    userName(){
      return this.$store.state.AppActiveUser.displayName
    },
    userEmail(){
      return this.$store.state.AppActiveUser.email
    },
    layout() {
      return this.viewMode ? 'table' : 'cards'
    }
  },
  methods: {
    closePopup(value){
      this.$emit('close', value)
    },
    loadData() {
      this.$vs.loading()

      this.service.getPlanToPurchase().then(
        response => {
          this.populateModels(response)
        },
        error => {}
      ).finally(() => {
          this.$vs.loading.close()
        })
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    populateModels(data){
      this.planListAll = data
      this.changePlanBillingCycle();
    },
    changePlanBillingCycle(billingCycle = 'MO'){
      let listFilter = this.planListAll.filter(plan => {
          return billingCycle === plan.billing_cycle
        })

      this.planList = listFilter
    },
    subscribe(planData){
      this.closePopup(true)
      const hash = planData.plan_encrypt
      this.$router.push(`/checkout/${hash}`)
    }
  },
  beforeMount() {
    this.service = PlansService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },
  watch: {
    visible(val){
      if(val){

      }
    },
    billing_cycle(val){
      this.changePlanBillingCycle(val)
    }
  },
}
</script>


<style lang="scss" scoped>
  ::v-deep .vs-popup {
    width: 800px !important;
  }

  ._class-card-title, ._class-card-description {
    @apply overflow-hidden;
    @apply text-justify;
    @apply break-words;
  }
  ._class-card-title {
    min-height: 1.5rem;
    max-height: 1.5rem;
  }
  ._class-card-description {
    min-height: 5rem;
    text-align: unset !important;
    //max-height: 5rem;
  }
  .playful /deep/ .vx-card__header {
  padding-top: 0.5rem;
  //background: padding-box linear-gradient(218deg,#00eef6,#00dde6 50%,#31acff);
  background: padding-box  linear-gradient(218deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), .7)) !important;
  border-radius: 0.4rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.playful /deep/ .vx-card__footer {
  border-top: 1px solid #b8c2cc80;
  padding: 0.25rem 0.50rem 0.50rem 0.50rem;
}

.playful /deep/ .vx-card__body {
  padding: 1rem;
}
</style>
